import API from "../lib/TimeEditAPI";
import PropTypes from "prop-types";
import React from "react";
import createReactClass from "create-react-class";
import FieldTableRow from "./FieldTableRow";
import { MillenniumDateTime, SimpleDateFormat } from "@timeedit/millennium-time";
import PinboardButton from "./PinboardButton";
import { ObjectSearch } from "../models/ObjectSearch";
import TypeSelection from "./TypeSelection";
import MultiSelection from "./MultiObjectSelect";
import ObjectList from "./ObjectListWithDropdown";
import ObjectSettingsConstants from "../lib/ObjectSettingsConstants";
import Language from "../lib/Language";
import Log from "../lib/Log";
import _ from "underscore";
import Expander from "./Expander";
import ColorCell from "./ColorCell";
import ColorPicker from "./ColorPicker";
import Mousetrap from "@timeedit/mousetrap";
import DateRange from "./DateRange";
import { Macros } from "../models/Macros";
import FieldInput from "./FieldInput";
import { TimeEdit } from "../lib/TimeEdit";
import { SourceType, ScratchpadObject } from "../models/Scratchpad";
import { withLDConsumer } from "launchdarkly-react-client-sdk";

const hasTimeLimit = (object) => object.begin !== undefined && object.end !== undefined;

const DEFAULT_MAX_FIELD_VALUES = 15;

const MAX_OBJECTS_TO_APPLY = 5;

const getListProperty = (searchProperty) => {
    if (searchProperty === "memberSearch") {
        return "members";
    }
    if (searchProperty === "relatedSearch") {
        return "related";
    }
    if (searchProperty === "availabilitySearch") {
        return "availability";
    }
    if (searchProperty === "optionalSearch") {
        return "optionalrelated";
    }
};

let removedPinboardObjects = [];
let addedPinboardObjects = [];

// Return the list of related types, with the root type last to discourage selecting it
const withRootTypeLast = (relatedTypes, rootTypeId) => {
    const rootType = relatedTypes.find((type) => type.id === rootTypeId);
    const otherTypes = relatedTypes.filter((type) => type.id !== rootTypeId);
    return otherTypes.concat([rootType]);
};

const importIfNotExists = (isNewObject, definitions, addUserOrganization, done) => {
    const doImport = () => {
        API.importObjects(definitions, addUserOrganization, undefined, (result) => {
            // Result is a list of status objects
            // Need to do something nice with multiple results
            let error = false;
            let ids = [];
            result.forEach((status) => {
                if (status.result < 0) {
                    error = true;
                    Log.error(status.details, status);
                } else {
                    ids.push(status.reference);
                }
            });
            done(ids, error);
        });
    };
    if (isNewObject) {
        const extIds = definitions.map((def) => def.extid).filter((extId) => extId !== "");
        if (extIds.length > 0) {
            API.exportObjects(extIds, (objects) => {
                if (objects.length !== 0) {
                    Log.error("An object with the same external id already exists.");
                } else {
                    doImport();
                }
            });
        } else {
            doImport();
        }
    } else {
        doImport();
    }
};

const ObjectDefinition = createReactClass({
    displayName: "ObjectDefinition",

    getInitialState() {
        return {
            displayObject: null, // The object used for display and editing
            objectIds: [], // Ids of objects we want to edit
            types: [], // The type(s) of which to consider the objects
            allTypes: [],
            createTypes: [],
            fieldDefs: [],
            showTypePanel: false,
            typeTree: null,
            organizations: [],
            memberSearch: new ObjectSearch({}, this.context.user.showExtraInfo).freeze(),
            relatedSearch: new ObjectSearch({}, this.context.user.showExtraInfo).freeze(),
            optionalSearch: new ObjectSearch({}, this.context.user.showExtraInfo).freeze(),
            availabilitySearch: new ObjectSearch({}, this.context.user.showExtraInfo).freeze(),
            isEditMembers: false,
            isEditRelated: false,
            isEditOptional: false,
            isEditAvailability: false,
            modified: false,
            modifiableFields: [],
            modifiableFieldsLoaded: false,
            availableKinds: [],
            colorsExpanded: false,
            addUserOrganization: false,
            multiModeProperties: [],
            maxFieldValues: DEFAULT_MAX_FIELD_VALUES,
        };
    },

    contextTypes: {
        user: PropTypes.object,
        colorDefs: PropTypes.array,
        presentModal: PropTypes.func,
        dismissModal: PropTypes.func,
        fireEvent: PropTypes.func,
        scratchpad: PropTypes.object,
    },

    componentDidMount() {
        removedPinboardObjects = [];
        addedPinboardObjects = [];
        this._isMounted = true;
        API.findTypes((types) => {
            this.setState({ allTypes: types });
        });
        API.getOrganizations({}, (orgs) => {
            this.setState({ organizations: orgs.parameters[0] });
        });
        API.getLimitation("importObjects", "num_field_values", (limit) => {
            this.setState({ maxFieldValues: limit });
        });
        if (this.props.setOnCloseCheck) {
            this.props.setOnCloseCheck(this.okToCancel);
        }
        this.updateObject(this.props);
        this.bindSave();
    },

    componentDidUpdate(prevProps) {
        if (
            !_.isEqual(prevProps.types, this.props.types) ||
            !_.isEqual(prevProps.objectIds, this.props.objectIds)
        ) {
            this.updateObject(this.props);
        }
    },

    componentWillUnmount() {
        this._isMounted = false;
        Mousetrap.unbindWithHelp("mod+s");

        if (this._previousSaveBinding) {
            Mousetrap.bindWithHelp(
                "mod+s",
                this._previousSaveBinding,
                undefined,
                Language.get("dialog_save")
            );
            this._previousSaveBinding = null;
        }
    },

    bindSave() {
        this._previousSaveBinding = Mousetrap.unbindWithHelp("mod+s", true)[0];
        Mousetrap.bindWithHelp(
            "mod+s",
            () => {
                this.saveObjects();
                return false;
            },
            undefined,
            Language.get("dialog_save")
        );
    },

    reset() {
        // Reset content, but keep data loaded in componentDidMount. Called by App.jsx.
        const resetState = this.getInitialState();
        resetState.allTypes = this.state.allTypes;
        resetState.organizations = this.state.organizations;
        this.setState(resetState);
    },

    setEditMode(propertyName, isEdit) {
        const newState = {};
        newState[propertyName] = isEdit;
        this.setState(newState);
    },

    getFieldDef(fieldId) {
        return _.find(this.state.fieldDefs, (field) => field.id === fieldId) || null;
    },

    getNameFromId(id, items) {
        const item = _.find(items, (itm) => itm.id === id);
        return item ? item.name : id;
    },

    getField(fieldId, object) {
        return _.find(object.fields, (field) => field.id === fieldId) || null;
    },

    getTypeNames() {
        return this.state.displayObject.types
            .map((type) => this.getNameFromId(type, this.state.allTypes))
            .join(", ");
    },

    getDefinition(id, definitions) {
        return _.find(definitions, (def) => def.id === id) || null;
    },

    updateObject(props) {
        let types = props.types || [];
        const objectIds = props.objectIds?.filter((oI) => oI !== 0) || [];
        const incompleteObjects = props.incompleteObjects || [];
        incompleteObjects.forEach((iO) => {
            types = [...types, ...iO.types];
        });
        const createCopy = props.createCopy || false;
        this.doUpdateObject(types, objectIds, incompleteObjects, createCopy);
    },

    isValidTypeOption(value) {
        if (value === null || value === undefined) {
            return false;
        }
        if (isNaN(value)) {
            return false;
        }
        return true;
    },

    updateSearches(memberTypes, relatedTypes, availabilityTypes, optionalTypes) {
        let changeFunction;
        if (memberTypes.length > 0) {
            changeFunction = this.getTypeChangeFunction("memberSearch");
            changeFunction(
                {
                    target: {
                        value: this.isValidTypeOption(this.state.memberSearch.type)
                            ? this.state.memberSearch.type
                            : memberTypes[0].id,
                    },
                },
                memberTypes
            );
        }
        if (relatedTypes.length > 0) {
            changeFunction = this.getTypeChangeFunction("relatedSearch");
            changeFunction(
                {
                    target: {
                        value: this.isValidTypeOption(this.state.relatedSearch.type)
                            ? this.state.relatedSearch.type
                            : relatedTypes[0].id,
                    },
                },
                relatedTypes
            );
        }
        if (availabilityTypes.length > 0 && availabilityTypes[0].types.length > 0) {
            changeFunction = this.getTypeChangeFunction("availabilitySearch"); // TODO, if type from search is the right thing, how to get it here?
            changeFunction(
                {
                    target: {
                        value: `${availabilityTypes[0].kind.id}:${availabilityTypes[0].types[0].id}`,
                    },
                },
                availabilityTypes
            );
        }
        if (optionalTypes.length > 0) {
            changeFunction = this.getTypeChangeFunction("optionalSearch");
            changeFunction(
                {
                    target: {
                        value: this.isValidTypeOption(this.state.optionalSearch.type)
                            ? this.state.optionalSearch.type
                            : this.state.optionalSearch.type,
                    },
                },
                optionalTypes
            );
        }
    },

    doUpdateObject(types, objectIds, incompleteObjects = [], createCopy = false) {
        // In the future, we could support more objects without given types.
        // We could then figure out common types given the objects.
        if (types.length === 0 && objectIds.length === 0 && incompleteObjects.length === 0) {
            return;
        }
        const self = this;
        if (objectIds.length === 1) {
            API.getModifiableObjectFields(objectIds[0], (modifiableFields) => {
                if (!this.props.viewOnly) {
                    self.setState({
                        modifiableFields: modifiableFields.map((field) => field.id),
                        modifiableFieldsLoaded: true,
                    });
                }
            });
            API.okToModifyObjects(objectIds, (modifyResult) => {
                const canModifyAll = modifyResult.reduce((ok, acc) => acc || ok, false);
                const addUserOrganization = createCopy && !canModifyAll;
                API.exportObjects(objectIds, (definitions) => {
                    const def = definitions[0];
                    API.getRelatedTypes(
                        def.types,
                        (def.members && def.members.length > 0) || false,
                        (def.related && def.related.length > 0) || false,
                        (def.availability && def.availability.length > 0) || false,
                        (def.optionalrelated && def.optionalrelated.length > 0) || false,
                        (result) => {
                            let memberSearch = self.state.memberSearch;
                            memberSearch = memberSearch.applySettings({ type: result[0][0] });
                            let relatedSearch = self.state.relatedSearch;
                            relatedSearch = relatedSearch.applySettings({ type: result[1][0] });
                            let availabilitySearch = self.state.availabilitySearch;
                            availabilitySearch = availabilitySearch.applySettings({
                                type: result[2][0],
                                parentValue: result[2][0] ? result[2][0].kind.id : null,
                            });
                            let optionalSearch = self.state.optionalSearch;
                            optionalSearch = optionalSearch.applySettings({ type: result[3][0] });
                            self.setState({
                                memberSearch,
                                memberTypes: result[0],
                                relatedSearch,
                                relatedTypes: result[1],
                                availabilitySearch,
                                availabilityTypes: result[2],
                                optionalSearch,
                                optionalTypes: result[3],
                                addUserOrganization,
                            });
                            self.updateFromTypes(
                                definitions,
                                def.types,
                                objectIds,
                                incompleteObjects,
                                createCopy
                            );
                        }
                    );
                });
            });
        } else {
            if (types.length === 0) {
                // eslint-disable-next-line no-param-reassign
                types = [1];
            }
            API.getRelatedTypes(types, false, false, false, false, (result) => {
                let memberSearch = self.state.memberSearch;
                memberSearch = memberSearch.applySettings({ type: result[0][0] });
                let relatedSearch = self.state.relatedSearch;
                relatedSearch = relatedSearch.applySettings({ type: result[1][0] });
                let availabilitySearch = self.state.availabilitySearch;
                availabilitySearch = availabilitySearch.applySettings({
                    type: result[2][0],
                    parentValue: result[2][0] ? result[2][0].kind.id : null,
                });
                let optionalSearch = self.state.optionalSearch;
                optionalSearch = optionalSearch.applySettings({ type: result[3][0] });
                self.setState({
                    memberSearch,
                    memberTypes: result[0],
                    relatedSearch,
                    relatedTypes: result[1],
                    availabilitySearch,
                    availabilityTypes: result[2],
                    optionalSearch,
                    optionalTypes: result[3],
                });
                if (objectIds.length < MAX_OBJECTS_TO_APPLY) {
                    API.exportObjects(objectIds, (definitions) => {
                        self.updateFromTypes(definitions, types, objectIds, incompleteObjects);
                    });
                } else {
                    self.updateFromTypes([], types, objectIds, incompleteObjects);
                }
            });
        }
    },

    setListProperty(object, propertyName, list, first, multiModeProperties) {
        if (first === true) {
            // eslint-disable-next-line no-param-reassign
            object[propertyName] = list || [];
        } else {
            if (!_.isEqual(object[propertyName], list)) {
                // eslint-disable-next-line no-param-reassign
                object[propertyName] = [];
                multiModeProperties.push(propertyName);
            }
        }
    },

    applyObject(newObject, object, first, numObjects, multiModeProperties, fieldDefs) {
        let j, field, currentField, fieldDefinition;
        const numFields = object.fields.length;
        const ignoreFields = [];
        for (j = 0; j < numFields; j++) {
            field = object.fields[j];
            fieldDefinition = this.getDefinition(field.id, fieldDefs);
            if (!fieldDefinition || (fieldDefinition.mandatory === true && numObjects > 1)) {
                ignoreFields.push(field.id);
                continue;
            }
            currentField = this.getField(field.id, newObject);
            if (first) {
                currentField.values = field.values;
                if (this.props.newFieldValues) {
                    if (fieldDefinition && this.props.newFieldValues[fieldDefinition.extid]) {
                        currentField.originalValues = field.values;
                        currentField.values = _.asArray(
                            this.props.newFieldValues[fieldDefinition.extid]
                        );
                    }
                }
            } else if (!_.isEqual(currentField.values, field.values)) {
                currentField.values = [];
                multiModeProperties.push(`field${currentField.id}`);
            }
        }
        // eslint-disable-next-line no-param-reassign
        newObject.color = object.color;
        // eslint-disable-next-line no-param-reassign
        newObject.fields = newObject.fields.filter(
            (nextField) => ignoreFields.indexOf(nextField.id) === -1
        );
        if (object.active === undefined) {
            // eslint-disable-next-line no-param-reassign
            object.active = true;
        }
        if (first) {
            // eslint-disable-next-line no-param-reassign
            newObject.active = object.active;
        } else if (object.active !== newObject.active) {
            multiModeProperties.push("active");
        }
        this.setListProperty(newObject, "orgs", object.orgs, first, multiModeProperties);
        this.setListProperty(newObject, "members", object.members, first, multiModeProperties);
        this.setListProperty(newObject, "related", object.related, first, multiModeProperties);
        this.setListProperty(
            newObject,
            "availability",
            object.availability_related,
            first,
            multiModeProperties
        );
        this.setListProperty(
            newObject,
            "optionalrelated",
            object.optional_related,
            first,
            multiModeProperties
        );
    },

    applyObjects(newObject, fieldDefs, definitions, objectIds, types, createCopy = false) {
        const multiModeProperties = [];
        const numObjects = definitions.length;
        let modified = this.state.modified;
        if (this.props.newFieldValues && Object.keys(this.props.newFieldValues).length > 0) {
            modified = true;
        }
        // eslint-disable-next-line no-param-reassign
        newObject.members = [];
        // eslint-disable-next-line no-param-reassign
        newObject.related = [];
        // eslint-disable-next-line no-param-reassign
        newObject.availability = [];
        // eslint-disable-next-line no-param-reassign
        newObject.optionalrelated = [];
        // eslint-disable-next-line no-magic-numbers
        if (numObjects < 2) {
            // One existing object, or creating a new one. Never be in multi mode.
            multiModeProperties.push("none");
        }
        if (numObjects === 0) {
            // eslint-disable-next-line no-param-reassign
            newObject.extid = "";
        }
        if (numObjects === 1) {
            const def = definitions[0];
            if (!createCopy) {
                // eslint-disable-next-line no-param-reassign
                newObject.id = def.id;
                // eslint-disable-next-line no-param-reassign
                newObject.extid = def.extid;
            }
            // eslint-disable-next-line no-param-reassign
            newObject.created = def.created;
            // eslint-disable-next-line no-param-reassign
            newObject.createdby = def.createdby;
            // eslint-disable-next-line no-param-reassign
            newObject.modified = def.modified;
            // eslint-disable-next-line no-param-reassign
            newObject.modifiedby = def.modifiedby;
            // eslint-disable-next-line no-param-reassign
            newObject.types = def.types;
            // eslint-disable-next-line no-param-reassign
            newObject.reservationkind = def.reservationkind;
            // eslint-disable-next-line no-param-reassign
            newObject.color = def.color;

            const userIds = [newObject.createdby, newObject.modifiedby]
                .filter((item) => !_.isNullish(item))
                .map((item) => item.id);
            API.getUsers(userIds, (users) => {
                users.forEach((user) => {
                    if (!_.isNullish(newObject.createdby) && user.id === newObject.createdby.id) {
                        // eslint-disable-next-line no-param-reassign
                        newObject.createdby = user;
                    }
                    if (!_.isNullish(newObject.modifiedby) && user.id === newObject.modifiedby.id) {
                        // eslint-disable-next-line no-param-reassign
                        newObject.modifiedby = user;
                    }
                });
                if (this._isMounted) {
                    this.setState({ displayObject: newObject, modified });
                }
            });
        }

        let i;
        let object;
        let first;
        if (numObjects === 0 && this.props.newFieldValues) {
            newObject.fields.forEach((currentField) => {
                const fieldDefinition = this.getDefinition(currentField.id, fieldDefs);
                if (fieldDefinition && this.props.newFieldValues[fieldDefinition.extid]) {
                    // eslint-disable-next-line no-param-reassign
                    currentField.originalValues = currentField.values;
                    // eslint-disable-next-line no-param-reassign
                    currentField.values = _.asArray(
                        this.props.newFieldValues[fieldDefinition.extid]
                    );
                }
            });
        }
        for (i = 0; i < numObjects; i++) {
            first = i === 0;
            object = definitions[i];
            this.applyObject(newObject, object, first, numObjects, multiModeProperties, fieldDefs);
        }

        const newState = {
            displayObject: newObject,
            fieldDefs,
            objectDefs: definitions,
            multiModeProperties,
            types,
            objectIds,
            modified,
        };
        if (objectIds.length === 0) {
            newState.modifiableFields = fieldDefs
                .filter((field) => field.editable || field.kind === FieldInput.fieldKind.CATEGORY)
                .map((field) => field.id);
            newState.modifiableFieldsLoaded = true;
        }
        if (this._isMounted) {
            this.setState(newState);
        }
    },

    updateFromTypes(definitions, types, objectIds, incompleteObjects = [], createCopy = false) {
        const self = this;
        API.getCreateTypesKinds(types, (availableKinds) => {
            this.setState({ availableKinds });

            API.getObjectTemplate(types, (newObject) => {
                // eslint-disable-next-line no-param-reassign
                newObject = newObject.parameters[0];
                if (!newObject.orgs) {
                    // eslint-disable-next-line no-param-reassign
                    newObject.orgs = [];
                }
                newObject.orgs.forEach((newOrg) => {
                    self.state.organizations.forEach((org) => {
                        if (org.id === newOrg.id) {
                            // eslint-disable-next-line no-param-reassign
                            newOrg.extid = org.extid;
                            // eslint-disable-next-line no-param-reassign
                            newOrg.name = org.extid;
                        }
                    });
                });
                if (!newObject.fields) {
                    // eslint-disable-next-line no-param-reassign
                    newObject.fields = [];
                }
                // eslint-disable-next-line no-param-reassign
                newObject.active = true;
                if (!_.contains(availableKinds, newObject.reservationkind)) {
                    // eslint-disable-next-line no-param-reassign
                    newObject.reservationkind = availableKinds[0];
                }

                API.getRelatedTypes(types, false, false, false, false, (result) => {
                    self.setState({
                        memberTypes: result[0],
                        relatedTypes: result[1],
                        availabilityTypes: result[2],
                        optionalTypes: result[3],
                    });
                    self.updateSearches(result[0], result[1], result[2], result[3]);
                    API.getFieldDefs(
                        newObject.fields.map((field) => field.id),
                        (fieldDefs) => {
                            if (objectIds.length < MAX_OBJECTS_TO_APPLY) {
                                if (objectIds.length === 0 && incompleteObjects.length === 0) {
                                    self.applyObjects(newObject, fieldDefs, [], objectIds, types);
                                } else {
                                    self.applyObjects(
                                        newObject,
                                        fieldDefs,
                                        [...definitions, ...incompleteObjects],
                                        objectIds,
                                        types,
                                        createCopy
                                    );
                                }
                            } else if (self._isMounted) {
                                // "Many" objects, be in multi mode for everything
                                // Make sure all properties are set with good empty values
                                self.updateState({
                                    types,
                                    objectIds,
                                    displayObject: newObject,
                                    fieldDefs,
                                    multiModeProperties: ["all"],
                                });
                            }
                        },
                        false
                    );
                });
            });
        });
    },

    validateFields(fields) {
        return _.every(fields, (field) => {
            const def = this.getFieldDef(field.id);
            return def.editable !== true || def.mandatory !== true || this.isValidField(field);
        });
    },

    isValidField(field) {
        return field.values && field.values.length !== 0 && Boolean(field.values[0]);
    },

    addField(field, include) {
        this.updateField(
            field,
            (values) => {
                if (values.length === this.state.maxFieldValues) {
                    return;
                }
                if (values.length === 0) {
                    // From the user"s perspective, zero values are displayed as one empty value. Thus we need to go from 0 -> 2.
                    values.push("");
                }
                values.push("");
            },
            include
        );
    },

    removeField(field, index, include) {
        this.updateField(field, (values) => values.splice(index, 1), include);
    },

    setFieldValue(field, event, newValue, valueIndex, include) {
        // eslint-disable-next-line no-param-reassign
        this.updateField(field, (values) => (values[valueIndex] = newValue), include);
    },

    clearExternalValue(field, definition, include) {
        this.updateField(
            field,
            (values) => {
                while (values.length) {
                    values.pop();
                }
                field.originalValues.forEach((value) => values.push(value));
            },
            include
        );
    },

    updateField(field, updateFn, include) {
        const includeFields = this.state.includeFields || [];
        const fields = [].concat(this.state.displayObject.fields);
        let i;
        for (i = 0; i < fields.length; i++) {
            if (fields[i].id === field.id) {
                const values = [].concat(fields[i].values);
                updateFn(values);
                fields[i] = _.extend({}, fields[i], { values });
                includeFields[i] = include;
            }
        }

        this.setState({
            includeFields,
            reservationFields: fields,
            fieldsValid: this.validateFields(fields),
            displayObject: _.extend({}, this.state.displayObject, { fields }),
            modified: true,
        });
    },

    colorChanged(newColor) {
        this.updateDisplayObject("color", newColor.id);
        this.setState({ colorsExpanded: false });
    },

    activeValueChanged(event) {
        this.updateDisplayObject("active", event.target.checked);
    },

    extidChanged(event) {
        this.updateDisplayObject("extid", event.target.value);
    },

    kindChanged(event) {
        this.updateDisplayObject("reservationkind", parseInt(event.target.value, 10));
    },

    isMultiFor(property) {
        if (this.state.multiModeProperties.indexOf("all") !== -1) {
            return true;
        }
        return this.state.multiModeProperties.indexOf(property) !== -1;
    },

    getUserTime(time, user, label) {
        const formatLong = Language.getDateFormat("date_f_yyyy_mm_dd_hh_mm");
        const timestamp = SimpleDateFormat.format(new MillenniumDateTime(time), formatLong);
        const userText = _.isNullish(user)
            ? Language.get("nc_missing_userid_info")
            : user.extid || user.id;
        return (
            <tr>
                <th className="fieldLabel">{label}</th>
                <td>
                    {timestamp} {userText}
                </td>
            </tr>
        );
    },

    organizationsChanged(newOrganizations) {
        const nO = newOrganizations.map((newOrg) => ({
            class: "organizationid",
            extid: newOrg.extid,
            id: newOrg.id,
            name: newOrg.name,
        }));
        this.updateDisplayObject("orgs", nO);
    },

    updateDisplayObject(prop, value) {
        const displayObject = _.extend({}, this.state.displayObject);
        displayObject[prop] = value;
        this.updateState({ displayObject });
    },

    addOrganization(event) {
        const displayObject = this.state.displayObject;
        const definition = this.getDefinition(
            parseInt(event.target.value, 10),
            this.state.organizations
        );
        if (definition !== null) {
            const newDefList = displayObject.orgs.concat(definition);
            this.updateDisplayObject("orgs", newDefList);
        }
    },

    applyFieldsToObject(displayObject, object) {
        const fields = displayObject.fields;
        const numFields = fields.length;
        let i, field, oldField;
        for (i = 0; i < numFields; i++) {
            if (this.state.includeFields[i] === true) {
                field = fields[i];
                oldField = this.getField(field.id, object);
                if (oldField === null) {
                    // An object of changed type may not have a field
                    oldField = {
                        // but is this the way to go in general?
                        id: field.id,
                    };
                    object.fields.push(oldField);
                }
                oldField.values = [].concat(field.values);
            }
        }
    },

    applyColorToObject(displayObject, object) {
        if (displayObject.color) {
            // eslint-disable-next-line no-param-reassign
            object.color = displayObject.color;
        }
    },

    applyActiveToObject(displayObject, object) {
        if (displayObject.active === true || displayObject.active === false) {
            // eslint-disable-next-line no-param-reassign
            object.active = displayObject.active;
        }
    },

    changeTypes(newTypes) {
        API.createEditableObject(newTypes, this.state.displayObject.id, (newObject) => {
            const currentObject = this.state.displayObject;
            currentObject.types = newObject.types.map((type) => type.id);
            const newFields = newObject.fields.map((newField) => {
                const oldField = _.find(currentObject.fields, (oF) => oF.id === newField.id);
                if (oldField) {
                    // eslint-disable-next-line no-param-reassign
                    newField.values = oldField.values;
                }
                return newField;
            });
            currentObject.fields = newFields;
            currentObject.members = newObject.members || [];
            currentObject.related = newObject.related || [];
            currentObject.availability = newObject.availability || [];
            currentObject.optionalrelated = newObject.optionalrelated || [];
            let availableKinds = [];
            let fieldDefs;
            let relatedTypes;
            const update = () => {
                this.updateState({
                    showTypePanel: false,
                    displayObject: currentObject,
                    types: currentObject.types,
                    fieldDefs,
                    modifiableFields: [],
                    modifiableFieldsLoaded: false,
                    memberTypes: relatedTypes[0],
                    relatedTypes: relatedTypes[1],
                    availabilityTypes: relatedTypes[2],
                    optionalTypes: relatedTypes[3],
                    availableKinds,
                });
                this.updateSearches(
                    relatedTypes[0],
                    relatedTypes[1],
                    relatedTypes[2],
                    relatedTypes[3]
                );
            };

            _.runAsync(
                [
                    (done) =>
                        API.getCreateTypesKinds(currentObject.types, (kinds) => {
                            availableKinds = kinds;
                            done();
                        }),
                    (done) =>
                        API.getFieldDefs(
                            newObject.fields.map((field) => field.id),
                            (fieldDefsFound) => {
                                fieldDefs = fieldDefsFound;
                                done();
                            },
                            false
                        ),
                    (done) =>
                        API.getRelatedTypes(
                            currentObject.types,
                            false,
                            false,
                            false,
                            false,
                            (result) => {
                                relatedTypes = result;
                                done();
                            }
                        ),
                ],
                update
            );
        });
    },

    saveObjects(event) {
        const displayObject = this.state.displayObject;
        const saveObjects = this.state.objectIds.filter((oI) => oI !== 0);
        const incompleteObjects = this.props.incompleteObjects || [];
        const self = this;
        if (incompleteObjects.length > 0) {
            // What to do and allow when it comes to mixing incomplete with other objects?
            const done = function (ids, error) {
                if (!error) {
                    Log.debug("Objects saved");
                    self.onObjectsSaved(ids);
                    self.onCancel(false, false, true);
                } else {
                    self.updateObject(self.props);
                }
            };
            incompleteObjects.forEach((definition) => {
                if (incompleteObjects.length === 1) {
                    // eslint-disable-next-line no-param-reassign
                    definition.extid = displayObject.extid;
                    if (definition.types !== displayObject.types) {
                        // eslint-disable-next-line no-param-reassign
                        definition.types = displayObject.types; // And is this the right place?
                        // eslint-disable-next-line no-param-reassign
                        definition.fields = []; // But should only clear non-shared fields
                    }
                }
                self.applyColorToObject(displayObject, definition);
                self.applyFieldsToObject(displayObject, definition);
                self.applyActiveToObject(displayObject, definition);
            }, self);
            const isNewObject = this.props.createCopy || this.props.isNewObject;
            importIfNotExists(isNewObject, incompleteObjects, this.state.addUserOrganization, done);
        } else if (saveObjects.length === 0 || saveObjects.length === 1) {
            // displayObject is a new object
            if (displayObject.extid === "") {
                displayObject.exid = null;
            }
            displayObject.related = displayObject.related.map((relObject) => {
                if (!(relObject instanceof Array)) {
                    return [relObject];
                }
                return relObject;
            });
            displayObject.availability_related = displayObject.availability;
            displayObject.optional_related = displayObject.optionalrelated;
            const isNewObject = this.props.createCopy || this.props.isNewObject;
            importIfNotExists(
                isNewObject,
                [displayObject],
                this.state.addUserOrganization,
                (ids, error) => {
                    if (error === false) {
                        Log.info(Language.get("nc_object_saved"));
                        self.onObjectsSaved(ids);
                        self.onCancel(false, false, true);
                    }
                }
            );
        } else {
            const smallerArrays = []; // will contain the sub-arrays of 10 elements each
            const arraySize = 100;
            for (let i = 0; i < Math.ceil(saveObjects.length / arraySize); i++) {
                smallerArrays.push(saveObjects.slice(i * arraySize, i * arraySize + arraySize));
            }
            let numSaved = 0;
            let numErrors = 0;
            const done = function (ids, error) {
                numSaved++;
                if (error) {
                    numErrors++;
                }
                if (numSaved === smallerArrays.length) {
                    if (numErrors === 0) {
                        Log.debug("Objects saved");
                        self.onObjectsSaved(saveObjects);
                        self.onCancel(false, false, true);
                    } else {
                        self.updateObject(self.props);
                    }
                }
            };
            smallerArrays.forEach((subSet) => {
                API.exportObjects(subSet, (definitions) => {
                    definitions.forEach((definition) => {
                        if (saveObjects.length === 1) {
                            // eslint-disable-next-line no-param-reassign
                            definition.extid = displayObject.extid;
                            if (definition.types !== displayObject.types) {
                                // eslint-disable-next-line no-param-reassign
                                definition.types = displayObject.types; // And is this the right place?
                                // eslint-disable-next-line no-param-reassign
                                definition.fields = []; // But should only clear non-shared fields
                            }
                        }
                        self.applyColorToObject(displayObject, definition);
                        self.applyFieldsToObject(displayObject, definition);
                        self.applyActiveToObject(displayObject, definition);
                    }, self);
                    const isNewObject = this.props.createCopy || this.props.isNewObject;
                    importIfNotExists(
                        isNewObject,
                        definitions,
                        this.state.addUserOrganization,
                        done
                    );
                });
            });
        }
        return event ? event.preventDefault() : null;
    },

    getCheckbox(label, disabled, value, isMulti, changeFunction) {
        if (isMulti) {
            return this.getMultiCheckbox(label, disabled, changeFunction);
        }
        return (
            <tr>
                <th className="fieldLabel">{label}</th>
                <td>
                    <input
                        onChange={changeFunction}
                        disabled={disabled}
                        type="checkbox"
                        checked={value}
                        value={value}
                    />
                </td>
            </tr>
        );
    },

    getMultiCheckbox(label, disabled, changeFunction) {
        return (
            <tr>
                <th className="fieldLabel">{label}</th>
                <td className="multiCheckbox">
                    <select onChange={changeFunction} disabled={disabled} value={-1}>
                        <option className="ignore" value={-1}>
                            {" "}
                            -{" "}
                        </option>
                        <option className="true" value={true}>
                            {Language.get("dynamic_object_list_active_item")}
                        </option>
                        <option className="false" value={false}>
                            {Language.get("dynamic_object_list_not_active")}
                        </option>
                    </select>
                </td>
            </tr>
        );
    },

    getList(
        items,
        editable,
        propertyName,
        dropdownOptions,
        label,
        onItemsChanged,
        onOptionSelected,
        searchProperty,
        totalNumber
    ) {
        const listItems = items.map((item) => {
            if (item.object || item.objects) {
                if (item.objects) {
                    return item.objects[0];
                }
                return item.object;
            }
            return item;
        });

        const showPinboard = this.props.flags?.pinboard && propertyName === "isEditMembers";
        return (
            <Expander label={label} id={`objectDef${propertyName}`} isOpenByDefault={false}>
                <ObjectList
                    items={listItems}
                    editable={editable}
                    propertyName={propertyName}
                    dropdownOptions={dropdownOptions}
                    extraRightSideMenuButton={
                        showPinboard ? (
                            <PinboardButton
                                showExtraInfo={this.context.showExtraInfo}
                                scratchpad={this.context.scratchpad}
                                onObjectSelected={(object) => {
                                    const removed = this.context.scratchpad.removeObjects([
                                        object.id,
                                    ]);
                                    removedPinboardObjects.push(removed[0]);
                                    this.setList(getListProperty(searchProperty), null, [
                                        ...items,
                                        { class: "objectid", id: object.id },
                                    ]);
                                }}
                                selectedType={this.state[searchProperty].type}
                            />
                        ) : null
                    }
                    hoverButton={
                        showPinboard ? (
                            <button
                                className="pinboardButton"
                                onClick={() => {
                                    this.isHoverClick = true;
                                }}
                                title={Language.get("nc_move_to_pinboard")}
                            />
                        ) : null
                    }
                    label={label}
                    onItemsChanged={onItemsChanged}
                    onOptionSelected={onOptionSelected}
                    onSelection={(item) => {
                        if (this.isHoverClick) {
                            const added = new ScratchpadObject(
                                item.id,
                                this.state.displayObject.id,
                                SourceType.Relation,
                                0,
                                item.id,
                                this.state[searchProperty].type
                            );
                            this.context.scratchpad.addObjects([added]);
                            addedPinboardObjects.push(added);
                            this.setList(
                                getListProperty(searchProperty),
                                null,
                                items.filter((it) => it.id !== item.id)
                            );
                        }
                        this.isHoverClick = false;
                    }}
                    editFunction={this.setEditMode.bind(this, propertyName, true)}
                    searchObject={this.state[searchProperty]}
                    updateSearchObject={this.updateSearchObject.bind(this, searchProperty)}
                    getAvailabilityObjects={this.getAvailabilityObjects}
                    totalNumber={totalNumber}
                    hasPeriodObjects={true}
                    presentMembershipPeriod={this.presentMembershipPeriodFunction(items)}
                />
            </Expander>
        );
    },

    setList(property, editProperty, newObjects) {
        const displayObject = this.state.displayObject;
        const currentObjects = displayObject[property];

        let nextObjects = newObjects;
        if (_.some(currentObjects, hasTimeLimit)) {
            nextObjects = nextObjects.map((nextObject) => {
                if (nextObject.begin || nextObject.end) {
                    return { begin: nextObject.begin, end: nextObject.end, object: nextObject };
                }
                return nextObject;
            });
        }
        if (property === "related") {
            nextObjects = nextObjects.map((newObject) => [newObject]);
        }

        if (property === "availability") {
            const dObj = [];
            this.state.availabilityTypes.forEach((availabilityType) => {
                const objects = newObjects.filter(
                    (obj) => obj.parentValue === availabilityType.kind.id
                );
                if (objects.length > 0) {
                    dObj.push({ kind: availabilityType.kind, objects });
                }
            });
            displayObject[property] = dObj;
        } else {
            displayObject[property] = nextObjects;
        }
        const newState = { displayObject };
        if (editProperty) {
            newState[editProperty] = false;
        }
        this.updateState(newState);
        this.updateSearches(
            this.state.memberTypes,
            this.state.relatedTypes,
            this.state.availabilityTypes,
            this.state.optionalTypes
        );
    },

    getAvailability(availabilityId) {
        if (!this.state.displayObject) {
            return null;
        }
        return _.find(
            this.state.displayObject.availability,
            (availability) => availability.kind.id === availabilityId
        );
    },

    getAvailabilityObjects(availabilityId) {
        const availability = this.getAvailability(availabilityId);
        if (availability && availability.objects) {
            return availability.objects;
        }
        return [];
    },

    getAvailabilityOptions() {
        const availabilityOptions = [];
        this.state.availabilityTypes.forEach((availabilityType) => {
            const group = {
                name: availabilityType.kind.name,
                id: availabilityType.kind.id,
                options: availabilityType.types.map((type) =>
                    _.extend({}, type, {
                        kind: availabilityType.kind,
                    })
                ),
            };
            availabilityOptions.push(group);
        });
        return availabilityOptions;
    },

    getEventValue(evt) {
        let value = evt.target.value;
        let parentValue;
        if (value && typeof value === "string" && value.indexOf(":") !== -1) {
            value = value.split(":");
            parentValue = parseInt(value[0], 10);
            value = parseInt(value[1], 10);
        } else if (value && value.id) {
            value = value.id;
        } else {
            value = parseInt(value, 10);
        }
        return { value, parentValue };
    },

    getSearchFunction(selectedObjects, updateStateFunction) {
        return (newSearchObject) => {
            const searchObject = newSearchObject.immutableSet({
                searchObjects: selectedObjects.map((object) => object.id),
                excludeObjects: [],
                otherObjectsMode: ObjectSettingsConstants.OTHER_OBJECTS.INCLUDE,
            });

            searchObject.search(0, () => {
                updateStateFunction(searchObject);
            });
        };
    },

    updateSearchObject(searchProperty, searchObject) {
        const state = {};
        state[searchProperty] = searchObject;
        this.updateState(state, false);
    },

    getTypeChangeFunction(type) {
        let searchObject = this.state[type];
        const self = this;
        const updateState = (newSearchObject) => {
            const state = {};
            state[type] = newSearchObject;
            self.updateState(state, false);
        };

        return (evt, selectedObjects) => {
            if (evt.target.value === "DUMMY") {
                return;
            }
            const { value, parentValue } = self.getEventValue(evt);
            if (parentValue) {
                // eslint-disable-next-line no-param-reassign
                selectedObjects = self.getAvailabilityObjects(parentValue);
                searchObject = searchObject.immutableSet({ parentValue });
            }
            searchObject.setType(value, self.getSearchFunction(selectedObjects, updateState));
        };
    },

    updateState(newState, considerModified = true, callback = _.noop) {
        if (!newState.modified && !this.state.modified && considerModified === true) {
            // eslint-disable-next-line no-param-reassign
            newState.modified = considerModified;
        }
        this.setState(newState, callback);
    },

    onObjectsSaved(objectIds) {
        this.context.fireEvent("objectEditor", Macros.Event.OBJECT_MODIFIED, objectIds);
        if (this.props.onSave) {
            this.props.onSave(objectIds);
        }
    },

    onPinboardItemAdded(item) {
        addedPinboardObjects.push(item);
    },

    onPinboardItemRemoved(item) {
        removedPinboardObjects.push(item);
    },

    resetPinboard() {
        if (removedPinboardObjects.length > 0) {
            this.context.scratchpad.addObjects(removedPinboardObjects);
            removedPinboardObjects = [];
        }
        if (addedPinboardObjects.length > 0) {
            this.context.scratchpad.removeObjects(
                addedPinboardObjects.map((object) => object.objectId)
            );
            addedPinboardObjects = [];
        }
    },

    onCancel(event, checkCallback = true, invokedBySave = false) {
        console.log("Cancel");
        if (!invokedBySave) {
            this.resetPinboard();
        }
        if (this.props.onCancel) {
            this.props.onCancel(event, checkCallback);
        }
        if (event) {
            return event.preventDefault();
        }
        return null;
    },

    editTypes() {
        if (this.state.displayObject.id === 0) {
            // eslint-disable-next-line no-alert
            alert(Language.get("nc_save_object_before_type_change"));
            return;
        }
        this.setState({ showTypePanel: true });
    },

    cancelTypeEdit() {
        this.setState({ showTypePanel: false });
    },

    okToCancel() {
        if (this.state.modified === false) {
            return true;
        }
        // eslint-disable-next-line no-alert
        return window.confirm(Language.get("nc_object_edited_close_without_saving?"));
    },

    canModifyField(fieldId) {
        if (this.props.viewOnly) {
            return false;
        }

        if (this.state.modifiableFieldsLoaded) {
            return this.state.modifiableFields.indexOf(fieldId) !== -1;
        }
        return true;
    },

    _selectedRange: null,

    setMembershipPeriod(object) {
        const onChange = (selectedRange) => {
            this._selectedRange = selectedRange;
        };
        const onDone = () => {
            const selectedRange = this._selectedRange;
            const begin = MillenniumDateTime.fromDate(selectedRange.startDate);
            const end = MillenniumDateTime.fromDate(selectedRange.endDate);
            const periodItem = {
                begin,
                end,
                object,
            };
            const memberList = []
                .concat(
                    this.state.displayObject.members.filter(
                        (mbr) => mbr.id !== object.id || (mbr.object && mbr.object.id !== object.id)
                    )
                )
                .concat(periodItem);
            this.setList("members", null, memberList);
        };
        const timedObject = _.find(
            this.state.displayObject.members,
            (member) => member.object && member.object.id === object.id
        );
        const buttons = [
            {
                title: Language.get("dialog_cancel"),
            },
            {
                title: Language.get("nc_dialog_done"),
                cb: onDone,
            },
        ];
        const content = (
            <DateRange
                startDate={timedObject ? timedObject.begin.getMillenniumDate() : null}
                endDate={timedObject ? timedObject.end.getMillenniumDate() : null}
                onChange={onChange}
            />
        );
        this.context.presentModal(content, null, Language.get("nc_member_period"), buttons);
    },

    presentMembershipPeriodFunction(objects) {
        return (id) => {
            const existingObject = _.find(objects, (item) => {
                if (item.objects) {
                    return hasTimeLimit(item) && _.some(item.objects, (obj) => obj.id === id);
                }
                return hasTimeLimit(item) && (item.object ? item.object.id === id : item.id === id);
            });
            if (existingObject) {
                const begin =
                    existingObject.begin && existingObject.begin.mts > 0
                        ? new MillenniumDateTime(existingObject.begin)
                        : null;
                const end =
                    existingObject.end && existingObject.end.mts > 0
                        ? new MillenniumDateTime(existingObject.end)
                        : null;
                const format = (time, isEnd = false) => {
                    if (!isEnd) {
                        const formatString = Language.getDateFormat("date_f_yy_mm_dd");
                        return SimpleDateFormat.format(time, formatString);
                    }
                    const formatString = Language.getDateFormat("date_f_yy_mm_dd_end");
                    return SimpleDateFormat.format(time, formatString);
                };

                if (!begin && !end) {
                    return null;
                }

                if (begin && !end) {
                    return `${format(begin)} - `;
                }
                if (!begin && end) {
                    return ` - ${format(end, true)}`;
                }

                return `${format(begin)} - ${format(end, true)}`;
            }
            return null;
        };
    },

    render() {
        let fieldInfo,
            idInfo,
            extidInfo,
            colorInfo,
            created,
            modified,
            kind,
            active,
            members,
            related,
            availability,
            optional,
            organizations;

        const object = this.state.displayObject;

        if (!object) {
            return <div className="objectDefinitionPane" />;
        }

        const disableAll =
            this.props.viewOnly ||
            (this.state.modifiableFieldsLoaded && this.state.modifiableFields.length === 0);

        if (this.state.showTypePanel) {
            return (
                <TypeSelection
                    allTypes={[].concat(this.state.allTypes)}
                    selectedTypes={[].concat(object.types)}
                    onSave={this.changeTypes}
                    onCancel={this.cancelTypeEdit}
                />
            );
        }

        if (this.state.isEditMembers) {
            return (
                <MultiSelection
                    isPinboardEnabled={this.props.flags?.pinboard}
                    onPinboardItemAdded={this.onPinboardItemAdded}
                    onPinboardItemRemoved={this.onPinboardItemRemoved}
                    parentId={object.id}
                    selectedValues={object.members.map((member) => {
                        if (!member.object) {
                            return member;
                        }
                        const result = _.clone(member.object);
                        result.begin = member.begin;
                        result.end = member.end;
                        return result;
                    })}
                    options={this.state.memberTypes}
                    onSave={this.setList.bind(this, "members", "isEditMembers")}
                    onCancel={() => {
                        this.resetPinboard();
                        this.setEditMode("isEditMembers", false);
                    }}
                    hasPeriodObjects={true}
                    setMembershipPeriod={this.setMembershipPeriod}
                />
            );
        }

        if (this.state.isEditRelated) {
            const relations =
                _.flatten(object.related.map((rel) => (rel.objects ? rel.objects : rel))) || [];
            return (
                <MultiSelection
                    onPinboardItemAdded={this.onPinboardItemAdded.bind(this)}
                    onPinboardItemRemoved={this.onPinboardItemRemoved.bind(this)}
                    parentId={object.id}
                    selectedValues={relations}
                    options={withRootTypeLast(this.state.relatedTypes, TimeEdit.rootType)}
                    onSave={this.setList.bind(this, "related", "isEditRelated")}
                    onCancel={() => {
                        this.resetPinboard();
                        this.setEditMode("isEditRelated", false);
                    }}
                    hasPeriodOjects={false}
                />
            );
        }

        if (this.state.isEditAvailability) {
            let availableObjects = [];
            if (object.availability.length > 0) {
                availableObjects = object.availability
                    .map((av) => av.objects.map((obj) => ({ ...obj, parentValue: av.kind.id })))
                    .reduce((a, b) => a.concat(b));
            }
            const availabilityOptions = this.getAvailabilityOptions();
            const valuesPerParent = {};
            object.availability.forEach((avlRel) => {
                valuesPerParent[avlRel.kind.id] = avlRel.objects;
            });
            return (
                <MultiSelection
                    onPinboardItemAdded={this.onPinboardItemAdded.bind(this)}
                    onPinboardItemRemoved={this.onPinboardItemRemoved.bind(this)}
                    parentId={object.id}
                    selectedValues={availableObjects}
                    options={availabilityOptions}
                    valuesPerParent={valuesPerParent}
                    getAvailabilityObjects={this.getAvailabilityObjects}
                    onSave={this.setList.bind(this, "availability", "isEditAvailability")}
                    onCancel={() => {
                        this.resetPinboard();
                        this.setEditMode("isEditAvailability", false);
                    }}
                />
            );
        }

        if (this.state.isEditOptional) {
            return (
                <MultiSelection
                    onPinboardItemAdded={this.onPinboardItemAdded.bind(this)}
                    onPinboardItemRemoved={this.onPinboardItemRemoved.bind(this)}
                    parentId={object.id}
                    selectedValues={object.optionalrelated}
                    options={this.state.optionalTypes}
                    onSave={this.setList.bind(this, "optionalrelated", "isEditOptional")}
                    onCancel={() => {
                        this.resetPinboard();
                        this.setEditMode("isEditOptional", false);
                    }}
                />
            );
        }

        if (this.context.user.isAdmin) {
            if (object.id) {
                idInfo = (
                    <tr>
                        <th className="fieldLabel">{Language.get("dynamic_object_info_id")}</th>
                        <td>{object.id}</td>
                        <td />
                    </tr>
                );
            }
            if (
                object.extid !== null &&
                object.extid !== undefined &&
                this.state.objectIds.length +
                    (this.props.incompleteObjects ? this.props.incompleteObjects.length : 0) <
                    // eslint-disable-next-line no-magic-numbers
                    2
            ) {
                extidInfo = (
                    <tr className="field normalField">
                        <th className="fieldLabel">{Language.get("dynamic_object_info_ext_id")}</th>
                        <td>
                            <input
                                style={{ width: "100%" }}
                                type="text"
                                disabled={this.props.viewOnly}
                                value={object.extid}
                                onChange={this.extidChanged}
                            />
                        </td>
                        <td />
                    </tr>
                );
            }
        }

        let colorDef = _.find(this.context.colorDefs, (def) => def.id === object.color);
        if (!colorDef) {
            colorDef = _.find(this.context.colorDefs, (def) => def.signature === "none");
        }
        if (!disableAll && this.state.colorsExpanded) {
            colorInfo = (
                <tr className="fieldNormalField">
                    <th className="fieldLabel">{Language.get("admin_color")}</th>
                    <td style={{ display: "flex" }}>
                        <ColorCell
                            color={
                                colorDef.signature === "none"
                                    ? ColorCell.NO_COLOR
                                    : colorDef.baseColor
                            }
                            pattern={
                                colorDef.signature === "none"
                                    ? ColorCell.NO_COLOR
                                    : colorDef.basePattern
                            }
                        />
                        <button
                            className="iconButton colorPickerButton"
                            onClick={() => {
                                this.setState({ colorsExpanded: !this.state.colorsExpanded });
                            }}
                        />
                        <div className="floatingPalette">
                            <ColorPicker
                                selectedColors={[object.color]}
                                colors={_.filter(
                                    this.context.colorDefs,
                                    (def) => def.signature === undefined
                                )}
                                onColorSelected={this.colorChanged}
                            />
                        </div>
                    </td>
                    <td />
                </tr>
            );
        } else {
            colorInfo = (
                <tr className="fieldNormalField">
                    <th className="fieldLabel">{Language.get("admin_color")}</th>
                    <td style={{ display: "flex" }}>
                        <ColorCell
                            color={
                                colorDef.signature === "none"
                                    ? ColorCell.NO_COLOR
                                    : colorDef.baseColor
                            }
                            pattern={
                                colorDef.signature === "none"
                                    ? ColorCell.NO_COLOR
                                    : colorDef.basePattern
                            }
                        />
                        <button
                            className="iconButton colorPickerButton"
                            onClick={() => {
                                this.setState({ colorsExpanded: !this.state.colorsExpanded });
                            }}
                        />
                    </td>
                    <td />
                </tr>
            );
        }

        if (object.created) {
            created = this.getUserTime(
                object.created,
                object.createdby,
                Language.get("dynamic_object_info_created")
            );
        }
        if (object.modified) {
            modified = this.getUserTime(
                object.modified,
                object.modifiedby,
                Language.get("dynamic_object_info_modified")
            );
        }

        // eslint-disable-next-line prefer-const
        active = this.getCheckbox(
            Language.get("dynamic_object_info_is_active"),
            disableAll,
            object.active,
            this.isMultiFor("active"),
            this.activeValueChanged
        );

        if (this.state.memberTypes && this.state.memberTypes.length > 0) {
            members = this.getList(
                object.members,
                !disableAll,
                "isEditMembers",
                { options: this.state.memberTypes },
                Language.get("dynamic_object_info_members"),
                null,
                this.getTypeChangeFunction("memberSearch"),
                "memberSearch",
                object.members.length
            );
        }
        if (this.state.relatedTypes && this.state.relatedTypes.length > 0) {
            const rel = object.related || [];
            related = this.getList(
                rel.reduce((all, relation) => all.concat(relation), []),
                !disableAll,
                "isEditRelated",
                { options: this.state.relatedTypes },
                Language.get("dynamic_object_info_relations"),
                null,
                this.getTypeChangeFunction("relatedSearch"),
                "relatedSearch",
                object.related.length
            );
        }
        if (this.state.availabilityTypes && this.state.availabilityTypes.length > 0) {
            const availableObjects =
                object.availability.length > 0 ? object.availability[0].objects : [];
            const totalNumber = object.availability.reduce(
                (prev, current) => prev + current.objects.length,
                0
            );
            availability = this.getList(
                availableObjects,
                !disableAll,
                "isEditAvailability",
                { options: this.getAvailabilityOptions() },
                Language.get("dynamic_object_info_availrel_title"),
                null,
                this.getTypeChangeFunction("availabilitySearch"),
                "availabilitySearch",
                totalNumber
            );
        }
        if (this.state.optionalTypes && this.state.optionalTypes.length > 0) {
            optional = this.getList(
                object.optionalrelated,
                !disableAll,
                "isEditOptional",
                { options: this.state.optionalTypes },
                Language.get("dynamic_object_info_optrel_title"),
                null,
                this.getTypeChangeFunction("optionalSearch"),
                "optionalSearch",
                object.optionalrelated.length
            );
        }
        let availableKinds = [];
        const kindNames = [
            Language.get("dynamic_object_info_is_standard"),
            Language.get("dynamic_object_info_is_abstract"),
            Language.get("dynamic_object_info_is_virtual_abstract"),
            Language.get("dynamic_object_info_is_virtual_standard"),
            Language.get("dynamic_object_info_is_template"),
        ];
        if (this.state.availableKinds && this.state.availableKinds.length > 1) {
            availableKinds = this.state.availableKinds.map((id) => (
                <option value={id} key={id}>
                    {" "}
                    {kindNames[id]}{" "}
                </option>
            ));
            kind = (
                <tr>
                    <th>{Language.get("dynamic_object_info_reservation_kind")}</th>
                    <td>
                        <select
                            value={this.state.displayObject.reservationkind}
                            onChange={this.kindChanged}
                            className="optionDropdown"
                        >
                            {" "}
                            {availableKinds}{" "}
                        </select>
                    </td>
                </tr>
            );
        } else {
            kind = (
                <tr>
                    <th>{Language.get("dynamic_object_info_reservation_kind")}</th>
                    <td> {kindNames[this.state.displayObject.reservationkind]} </td>
                </tr>
            );
        }

        const orgMap = object.orgs.map((org) => ({
            id: org.id,
            extid: org.extid.split(";").pop(),
            name: org.extid.split(";").pop(),
        }));
        const orgIds = object.orgs.map((org) => org.id);
        const orgFilter = this.state.organizations.filter((org) => orgIds.indexOf(org.id) === -1);
        // eslint-disable-next-line prefer-const
        organizations = this.getList(
            orgMap,
            !disableAll,
            null,
            { options: orgFilter, headlineValue: Language.get("nc_option_select") },
            Language.get("dynamic_object_info_orgs"),
            this.organizationsChanged,
            this.addOrganization,
            undefined,
            orgMap.length
        );

        // eslint-disable-next-line prefer-const
        fieldInfo = object.fields.map(function (field) {
            const definition = this.getFieldDef(field.id);
            if (definition === null) {
                if (!field.values || field.values.length === 0) {
                    return null;
                }
                return (
                    <tr key={`${object.id}.${field.id}`}>
                        <th className="fieldLabel">{field.id}</th>
                        <td colSpan="2">{field.values.join(", ")}</td>
                    </tr>
                );
            }
            const externalValue = this.props.newFieldValues[definition.extid];
            const hasExternalValue =
                externalValue !== undefined && field.values.indexOf(externalValue) !== -1;
            const key = `${object.id}.${field.id}`;
            return (
                <FieldTableRow
                    key={key}
                    editable={this.canModifyField(field.id)}
                    field={field}
                    definition={definition}
                    hasExternalValue={hasExternalValue}
                    clearExternalValue={this.clearExternalValue.bind(this, field)}
                    maxFieldValues={this.state.maxFieldValues}
                    addField={this.addField.bind(this, field)}
                    removeField={this.removeField.bind(this, field)}
                    setFieldValue={this.setFieldValue.bind(this, field)}
                    includeCheckbox={this.state.objectIds.length > 1}
                />
            );
        }, this);

        let typeEditButton = null;
        if (!disableAll) {
            typeEditButton = <div className="editIcon" onClick={this.editTypes} />;
        }

        // eslint-disable-next-line prefer-const
        let statusMessages =
            this.props.incompleteObjects.length > 0 ? (
                <div>
                    {this.props.incompleteObjects.map((io) => (
                        <p key={io.extid}>{`${io.extid}: ${io.status}`}</p>
                    ))}
                </div>
            ) : null;

        return (
            <div className="objectDefinitionPane">
                {statusMessages}
                <table>
                    <tbody>
                        <tr>
                            <td className="column">
                                <table key={`${object.id}.fields`}>
                                    <tbody>
                                        {idInfo}
                                        {extidInfo}
                                        {colorInfo}
                                        <tr>
                                            <td colSpan="3">&nbsp;</td>
                                        </tr>
                                    </tbody>
                                    {fieldInfo}
                                </table>
                            </td>
                            <td className="column">
                                <table key={`${object.id}.other`}>
                                    <tbody>
                                        <tr>
                                            <th className="fieldLabel">
                                                {Language.get("dynamic_object_info_type")}
                                            </th>
                                            <td>
                                                {typeEditButton}
                                                {this.getTypeNames()}
                                            </td>
                                        </tr>
                                        {created}
                                        {modified}
                                        {kind}
                                        {active}
                                        {members}
                                        {related}
                                        {availability}
                                        {optional}
                                        {organizations}
                                    </tbody>
                                </table>
                            </td>
                        </tr>
                    </tbody>
                </table>
                {this._renderButtons(disableAll)}
            </div>
        );
    },

    _renderButtons(disableAll) {
        let fieldsValid = true;
        if (this.state.fieldsValid !== null && this.state.fieldsValid !== undefined) {
            fieldsValid = this.state.fieldsValid === true;
        }

        if (this.props.viewOnly) {
            return (
                <form className="bottomButtons btnGroup horizontal" onSubmit={this.saveObjects}>
                    <button className="cancel" onClick={this.onCancel}>
                        {Language.get("menu_close")}
                    </button>
                </form>
            );
        }

        const submitLabel = Language.get("dynamic_object_info_save");
        return (
            <form className="bottomButtons btnGroup horizontal" onSubmit={this.saveObjects}>
                <button className="cancel" onClick={this.onCancel}>
                    {Language.get("dialog_cancel")}
                </button>
                <button
                    type="submit"
                    className="save"
                    disabled={!(fieldsValid && this.state.modified === true && !disableAll)}
                >
                    {submitLabel}
                </button>
            </form>
        );
    },
});

export default withLDConsumer()(ObjectDefinition);
