import { McFluffy } from "../models/McFluffy";
import API from "./TimeEditAPI";
import _ from "underscore";
import ObjectSettingsConstants from "./ObjectSettingsConstants";
import Language from "./Language";

const getFirstFreeObject = (
    reservation,
    currentObject,
    virtualObjects,
    objectSearch,
    allObjectIds,
    index,
    useRelatedObjects = false,
    reservationLayer = null,
    skipLastReserved = undefined,
    callback = _.noop
) => {
    const searchForObjects = () => {
        const search = objectSearch.immutableSet({
            beginTime: reservation.begin,
            endTime: reservation.end,
            reserveMode: true,
            searchObjects: [],
            excludeObjects: [],
            excludeReservations: [reservation.id],
            otherObjectsMode: ObjectSettingsConstants.OTHER_OBJECTS.EXCLUDE,
        });
        search.search(0, (objects) => callback(objects[index]), false, 0, reservationLayer);
    };
    if (useRelatedObjects) {
        // Goes instead of virtual, or standard, right?
        API.getSuggestedObjectsAdvanced2(
            {
                class: "teobjecttype",
                id: currentObject.id,
                type: { class: "typeid", id: currentObject.type },
            }, //
            reservation.objects.filter((obj) => obj.id !== currentObject.id), // Other reservation objects, except the virtual one, if any
            true, // isReservationMode
            0, // templateGroupId
            reservation.begin,
            reservation.end,
            allObjectIds,
            reservation.id,
            skipLastReserved,
            (result) => {
                if (result[0].length <= index) {
                    // If no suggested objects, regular results should be used
                    // Is this true in this case as well, or should we skip out?
                    //searchForObjects();
                    callback(null);
                } else {
                    const obj = result[0][index];
                    obj.typeId = currentObject.type;
                    callback(obj);
                }
            }
        );
    } else if (virtualObjects.length > 0) {
        API.getSuggestedObjectsSimple(
            virtualObjects[0],
            virtualObjects[0].type,
            allObjectIds,
            reservation.begin,
            reservation.end,
            (result) => {
                if (result[0].length <= index) {
                    // If no suggested objects, regular results should be used
                    searchForObjects();
                } else {
                    const obj = result[0][index];
                    obj.typeId = virtualObjects[0].type;
                    callback(obj);
                }
            }
        );
    } else {
        searchForObjects();
    }
};

const doAssignObject = (
    reservation,
    currentObject,
    virtualObjects,
    virtualTypeItems,
    objectSearch,
    index,
    allObjectIds,
    useRelatedObjects,
    reservationLayer,
    skipLastReserved,
    callback
) => {
    getFirstFreeObject(
        reservation,
        currentObject,
        virtualObjects,
        objectSearch,
        allObjectIds,
        index,
        useRelatedObjects,
        reservationLayer,
        skipLastReserved,
        (object) => {
            if (!object) {
                //console.error(Language.get("nc_auto_object_no_free_object_found"));
                callback([
                    [],
                    [reservation],
                    [Language.get("nc_auto_object_no_free_object_found")],
                ]);
            } else {
                API.replaceObjectsOnReservations(
                    [reservation.id],
                    object.id,
                    object.typeId,
                    virtualTypeItems.length > 0 ? virtualTypeItems[0].object.id : null,
                    undefined,
                    true,
                    false, // addIfMissingTypeOnly
                    true,
                    false, // allowDoubleBooking
                    (result) => {
                        if (result[0].length > 0) {
                            callback(result);
                        } else {
                            //console.log(result[2]);
                            // No successes, try next object
                            doAssignObject(
                                reservation,
                                currentObject,
                                virtualObjects,
                                virtualTypeItems,
                                objectSearch,
                                index + 1,
                                allObjectIds,
                                useRelatedObjects,
                                reservationLayer,
                                skipLastReserved,
                                callback
                            );
                        }
                    },
                    (errorType, failedMessage) => {
                        // Error
                        //console.log(errorType, failedMessage);
                        callback([[], [reservation.id], [failedMessage]]);
                    }
                );
            }
        }
    );
};

const assignObjectToReservation = (
    reservationId,
    allObjectIds,
    baseFluffy,
    objectSearch,
    useRelatedObjects,
    reservationLayer,
    skipLastReserved,
    callback
) => {
    API.setReservationToMcFluffy([reservationId], baseFluffy, true, null, false, (setResult) => {
        // eslint-disable-next-line consistent-return
        API.getReservations([reservationId], (reservations) => {
            const reservation = reservations[0];
            const fluffy = McFluffy.create(setResult.parameters[0], []);
            const virtualTypeItems = fluffy
                .objectItemsForType(objectSearch.type)
                .filter((item) => item.virtual === true && item.object.id !== 0);
            const virtualObjects = virtualTypeItems.map((item) => ({
                id: item.object.id,
                type: item.type.id,
                subtypes: item.subtypes ? item.subtypes.map((st) => st.id) : undefined,
            }));
            let currentObject = fluffy.objectItemsForType(objectSearch.type)[0];
            if (!currentObject) {
                return callback([
                    [],
                    [reservation],
                    [
                        Language.get(
                            "nc_auto_object_no_object_of_type_on_reservation",
                            reservationId
                        ),
                    ],
                ]);
            }
            currentObject = {
                id: currentObject.object.id,
                type: currentObject.type.id,
            };
            doAssignObject(
                reservation,
                currentObject,
                virtualObjects,
                virtualTypeItems,
                objectSearch,
                0,
                allObjectIds,
                useRelatedObjects,
                reservationLayer,
                skipLastReserved,
                (result) => {
                    if (virtualObjects.length > 1) {
                        const selectedObjectIds = fluffy
                            .objectItemsForType(objectSearch.type)
                            .map((obj) => obj.object.id);
                        assignObjectToReservation(
                            reservationId,
                            allObjectIds.filter((id) => selectedObjectIds.indexOf(id) === -1),
                            baseFluffy,
                            objectSearch,
                            useRelatedObjects,
                            reservationLayer,
                            skipLastReserved,
                            callback
                        );
                    } else {
                        callback(result);
                    }
                }
            );
        });
    });
};

const AutoScheduler = {
    assignObjectToReservation,
};

export default AutoScheduler;
