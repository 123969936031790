/* eslint-disable no-param-reassign */
const positionAndAnimate = function (element, baseElement, cssClass, duration, delay) {
    if (!baseElement) {
        return;
    }
    const clientRect = baseElement.getBoundingClientRect();
    element.style.top = `${clientRect.top}px`;
    element.style.left = `${clientRect.left}px`;
    element.style["pointer-events"] = "none"; // Keep an animation from blocking other interactions.
    element.style.position = "absolute";

    document.body.appendChild(element);
    if (!delay) {
        delay = 0;
    }
    setTimeout(() => {
        if (Array.isArray(cssClass)) {
            cssClass.forEach((cls) => {
                element.classList.toggle(cls);
            });
        } else {
            element.classList.toggle(cssClass);
        }

        setTimeout(() => {
            if (element && element.parentNode) {
                element.parentNode.removeChild(element);
            } else {
                element = null;
            }
        }, duration);
    }, delay);
};

const Animation = function (baseElement, cssClass, duration, delay) {
    const element = baseElement.cloneNode(true); // true means deep copy.
    element.id = `${baseElement.id}clone`;
    element["data-reactid"] = `${baseElement["data-reactid"]}clone`;

    positionAndAnimate(element, baseElement, cssClass, duration, delay);
};

Animation.fallOut = function (element) {
    // eslint-disable-next-line no-magic-numbers
    return Animation.applyClass(element, "fallOut", 1000, 0);
};

Animation.liftOff = function (element, delay = 0) {
    // eslint-disable-next-line no-magic-numbers
    return Animation.applyClass(element, "liftOff", 1000, delay);
};

Animation.applyClass = function (element, className, duration, delay) {
    return new Animation(element, className, duration, delay);
};

export default Animation;
